import React from "react"
import Faq from "react-faq-component"

import Layout from "../components/Layout"

const data = {
  title: "Frequently Asked Questions",
  rows: [
    // {
    //   title: "Question",
    //   content: <p>Answer</p>,
    // },
  ],
}

const FAQ = () => {
  return (
    <Layout>
      <div style={styles.main}>
        <Faq data={data} styles={styles.faq} />
      </div>
    </Layout>
  )
}

export default FAQ

const styles = {
  main: {
    width: "100%",
    maxWidth: 800,
    padding: 50,
    marginTop: 50,
    marginBottom: 50,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    borderRadius: 20,
  },
  faq: {
    bgColor: "transparent",
    titleTextColor: "#303030",
    rowTitleColor: "#303030",
    rowContentColor: "#606060",
  },
}
